.personal-sprint {
  padding-top: 15px; }

.sprints {
  justify-content: center;
  flex-wrap: nowrap !important; }
  .sprints .sprint {
    padding: 5px 18px 16px 18px !important;
    margin-bottom: 10px !important; }
    .sprints .sprint .current-budge {
      height: auto !important;
      padding: 2px 23px 1px 23px !important;
      margin-bottom: 10px; }
    .sprints .sprint .badge {
      font-size: 10px;
      font-family: HelveticaNeueRegular;
      text-transform: uppercase;
      line-height: 13px;
      min-width: 74px;
      border-radius: 10px;
      height: 16px;
      padding: 0.25em 18px; }

.sprints,
.sprint-employees {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: wrap;
  position: relative;
  margin-left: auto;
  margin-right: auto; }
  .sprints .badge.badge-primary,
  .sprint-employees .badge.badge-primary {
    background-color: #4990e2; }
  .sprints .issue,
  .sprint-employees .issue {
    background: #fff;
    padding: 10px;
    margin: 0 0 10px 0;
    min-height: 120px;
    border-bottom: 1px solid #e3e3e3; }
    .sprints .issue.eq-height,
    .sprint-employees .issue.eq-height {
      margin-bottom: -99999px;
      padding-bottom: 99999px; }
    .sprints .issue .c1,
    .sprints .issue .c2,
    .sprints .issue .c3,
    .sprint-employees .issue .c1,
    .sprint-employees .issue .c2,
    .sprint-employees .issue .c3 {
      margin-bottom: 5px; }
    .sprints .issue .c2,
    .sprint-employees .issue .c2 {
      min-width: 50px;
      font-size: 15px;
      font-family: "HelveticaNeueRegular";
      color: #4990e2; }
    .sprints .issue .c3,
    .sprint-employees .issue .c3 {
      min-width: 70px; }
    .sprints .issue-money.success,
    .sprint-employees .issue-money.success {
      color: #01BC65; }
    .sprints .issue-money.failure,
    .sprint-employees .issue-money.failure {
      color: #d0011b; }
    .sprints .issue-hours,
    .sprint-employees .issue-hours {
      font-size: 15px;
      line-height: 30px;
      font-family: "HelveticaNeueMedium";
      color: #d0011b; }
    .sprints .issue-qcoefficient-value,
    .sprint-employees .issue-qcoefficient-value {
      cursor: pointer;
      color: white; }
      .sprints .issue-qcoefficient-value.bold,
      .sprint-employees .issue-qcoefficient-value.bold {
        color: #1d1e1f;
        font-size: 14px;
        line-height: 26px;
        font-family: "HelveticaNeueBold"; }
        .sprints .issue-qcoefficient-value.bold span,
        .sprint-employees .issue-qcoefficient-value.bold span {
          font-size: 18px; }
    .sprints .issue-qcoefficient-input input,
    .sprint-employees .issue-qcoefficient-input input {
      width: 80px; }
    .sprints .issue-buttons > div,
    .sprint-employees .issue-buttons > div {
      display: inline-block;
      position: relative;
      width: 24px;
      height: 24px;
      border: 1px solid #979797;
      margin: 0 5px;
      text-align: center;
      font-size: 14px;
      color: #979797;
      font-weight: 100;
      cursor: pointer; }
    .sprints .issue-buttons .fill,
    .sprint-employees .issue-buttons .fill {
      background-color: #01BC65;
      border-color: #01BC65;
      color: #fff; }
    .sprints .issue-buttons .success,
    .sprint-employees .issue-buttons .success {
      border-color: #01BC65;
      color: #01BC65; }
    .sprints .issue-buttons .primary,
    .sprint-employees .issue-buttons .primary {
      border-color: #4990e2;
      color: #4990e2; }
    .sprints .issue-buttons .fa-play,
    .sprint-employees .issue-buttons .fa-play {
      position: absolute;
      right: 4px; }
    .sprints .issue-title,
    .sprint-employees .issue-title {
      font-size: 15px;
      font-weight: 700;
      font-family: "HelveticaNeueRegular";
      margin-top: 10px;
      line-height: 16px;
      color: #000; }
    .sprints .issue .components-label,
    .sprint-employees .issue .components-label {
      font-size: 15px;
      font-family: "HelveticaNeueThinItalic";
      margin-top: 10px;
      color: #9b9b9b; }
    .sprints .issue-info > img,
    .sprint-employees .issue-info > img {
      width: 16px;
      height: 16px; }
    .sprints .issue-info > a,
    .sprint-employees .issue-info > a {
      margin: 0 5px;
      white-space: nowrap; }
    .sprints .issue-info > .badge,
    .sprint-employees .issue-info > .badge {
      font-size: 10px;
      font-family: "HelveticaNeueBold";
      text-transform: uppercase;
      line-height: 10px;
      border-radius: 10px;
      height: 19px;
      padding-top: 4px; }
    .sprints .issue.issue-pause .issue-info > .badge,
    .sprint-employees .issue.issue-pause .issue-info > .badge {
      background-color: #9b9b9b; }
  .sprints .sprint-list .section-header,
  .sprints .sprint-issues .section-header,
  .sprints .sprint-worklogs .section-header,
  .sprint-employees .sprint-list .section-header,
  .sprint-employees .sprint-issues .section-header,
  .sprint-employees .sprint-worklogs .section-header {
    font-size: 22px;
    color: #9b9b9b;
    font-family: "HelveticaNeueRegular";
    margin-bottom: 5px; }
    .sprints .sprint-list .section-header span,
    .sprints .sprint-issues .section-header span,
    .sprints .sprint-worklogs .section-header span,
    .sprint-employees .sprint-list .section-header span,
    .sprint-employees .sprint-issues .section-header span,
    .sprint-employees .sprint-worklogs .section-header span {
      font-size: 24px;
      color: #000;
      margin: 0 5px; }
    .sprints .sprint-list .section-header .badge,
    .sprints .sprint-issues .section-header .badge,
    .sprints .sprint-worklogs .section-header .badge,
    .sprint-employees .sprint-list .section-header .badge,
    .sprint-employees .sprint-issues .section-header .badge,
    .sprint-employees .sprint-worklogs .section-header .badge {
      font-size: 12px;
      font-family: HelveticaNeueRegular;
      line-height: 15px;
      font-weight: 100;
      height: 20px;
      vertical-align: middle;
      margin-right: 7px;
      text-align: center;
      border-radius: 16px;
      padding-left: 10px;
      padding-right: 10px; }
  .sprints .sprint-list .section:first-child,
  .sprints .sprint-issues .section:first-child,
  .sprints .sprint-worklogs .section:first-child,
  .sprint-employees .sprint-list .section:first-child,
  .sprint-employees .sprint-issues .section:first-child,
  .sprint-employees .sprint-worklogs .section:first-child {
    margin-top: 0; }
  .sprints .sprint-day,
  .sprint-employees .sprint-day {
    border-bottom: 1px solid #e3e3e3;
    background-color: #f3f3f3; }
    .sprints .sprint-day-info,
    .sprint-employees .sprint-day-info {
      min-width: 120px;
      border-right: 1px solid #e3e3e3;
      padding: 0 10px 0 0; }
      .sprints .sprint-day-info__dow,
      .sprint-employees .sprint-day-info__dow {
        font-size: 15px;
        line-height: 16px;
        color: #4a4a4a;
        font-family: "HelveticaNeueBold";
        text-transform: capitalize; }
      .sprints .sprint-day-info__date,
      .sprint-employees .sprint-day-info__date {
        font-size: 15px;
        font-family: "HelveticaNeueRegular";
        line-height: 16px;
        color: #9b9b9b; }
      .sprints .sprint-day-info__start-time,
      .sprint-employees .sprint-day-info__start-time {
        position: relative;
        margin-top: 10px;
        font-size: 14px;
        line-height: 14px;
        color: #9b9b9b;
        font-family: "HelveticaNeueBold"; }
        .sprints .sprint-day-info__start-time:after,
        .sprint-employees .sprint-day-info__start-time:after {
          content: url("../images/arrow-down-long.png");
          top: 15px;
          left: 12px;
          position: absolute; }
      .sprints .sprint-day-info__end-time,
      .sprint-employees .sprint-day-info__end-time {
        font-size: 14px;
        font-family: "HelveticaNeueBold";
        line-height: 14px;
        color: #9b9b9b; }
      .sprints .sprint-day-info__duration,
      .sprint-employees .sprint-day-info__duration {
        font-size: 18px;
        font-family: "HelveticaNeueBold";
        line-height: 18px;
        color: #d0011b;
        padding-left: 50px;
        font-weight: 600; }
        .sprints .sprint-day-info__duration.success,
        .sprint-employees .sprint-day-info__duration.success {
          color: #01BC65; }
    .sprints .sprint-day-worklog,
    .sprint-employees .sprint-day-worklog {
      margin-left: 10px; }
      .sprints .sprint-day-worklog-time,
      .sprint-employees .sprint-day-worklog-time {
        font-size: 14px;
        font-family: "HelveticaNeueBold";
        color: #4990e2; }
      .sprints .sprint-day-worklog-item > img,
      .sprint-employees .sprint-day-worklog-item > img {
        width: 12px;
        height: 12px;
        margin: 0 5px; }
      .sprints .sprint-day-worklog-item > a,
      .sprint-employees .sprint-day-worklog-item > a {
        font-size: 11px;
        font-family: "HelveticaNeueBold";
        min-width: 65px; }
      .sprints .sprint-day-worklog-item > a.sprint-day-worklog-item__delete,
      .sprint-employees .sprint-day-worklog-item > a.sprint-day-worklog-item__delete {
        min-width: 18px;
        padding-left: 3px; }
      .sprints .sprint-day-worklog-item__duration,
      .sprint-employees .sprint-day-worklog-item__duration {
        cursor: pointer;
        font-size: 12px;
        font-family: "HelveticaNeueRegular";
        color: #9b9b9b; }
      .sprints .sprint-day-worklog-item__title,
      .sprint-employees .sprint-day-worklog-item__title {
        font-size: 10px;
        font-family: "HelveticaNeueMedium";
        color: #000;
        margin-left: 10px !important; }
  .sprints .sprint,
  .sprint-employees .sprint {
    background: #fff;
    padding: 0 10px 20px 10px;
    margin-top: 10px;
    margin-right: 10px;
    opacity: 0.5; }
    .sprints .sprint.active,
    .sprint-employees .sprint.active {
      opacity: 1;
      border: 2px solid darkgray;
      padding: 0 15px;
      padding-bottom: 20px; }
    .sprints .sprint .col-md-4,
    .sprints .sprint .col-md-6,
    .sprint-employees .sprint .col-md-4,
    .sprint-employees .sprint .col-md-6 {
      padding: 0; }
    .sprints .sprint-list,
    .sprint-employees .sprint-list {
      box-sizing: content-box;
      border-right: 1px solid silver;
      padding-right: 10px; }
    .sprints .sprint-issues-hours > div:last-child,
    .sprint-employees .sprint-issues-hours > div:last-child {
      margin-right: 0; }
    .sprints .sprint-issues .section-content > div,
    .sprint-employees .sprint-issues .section-content > div {
      padding: 15px 20px; }
      .sprints .sprint-issues .section-content > div a,
      .sprint-employees .sprint-issues .section-content > div a {
        font-family: HelveticaNeueMedium;
        color: #4a90e2;
        margin-top: 4px;
        font-size: 18px; }
      .sprints .sprint-issues .section-content > div .badge,
      .sprint-employees .sprint-issues .section-content > div .badge {
        height: 16px;
        font-family: HelveticaNeueRegular;
        margin-left: 5px;
        padding-right: 23px;
        padding-left: 23px; }
    .sprints .sprint .vertical-line,
    .sprint-employees .sprint .vertical-line {
      width: 1.1px;
      background-color: silver;
      margin: 0 1px;
      opacity: 0.4; }
    .sprints .sprint-tasks-info, .sprints .sprint-salary-info,
    .sprint-employees .sprint-tasks-info,
    .sprint-employees .sprint-salary-info {
      padding: 0 5px;
      width: 50%; }
    .sprints .sprint .primary,
    .sprint-employees .sprint .primary {
      color: #4990e2; }
    .sprints .sprint .warning,
    .sprint-employees .sprint .warning {
      color: #f0ad4e; }
    .sprints .sprint .danger,
    .sprint-employees .sprint .danger {
      color: #d9534f; }
    .sprints .sprint .black,
    .sprint-employees .sprint .black {
      color: #000; }
    .sprints .sprint > .badge,
    .sprint-employees .sprint > .badge {
      border-radius: 10px;
      min-width: 130px;
      text-transform: uppercase;
      font-size: 9px;
      padding: 3px 2px; }
    .sprints .sprint-date,
    .sprint-employees .sprint-date {
      font-size: 32px;
      margin-top: 1;
      line-height: 40px;
      font-family: "HelveticaNeueMedium"; }
    .sprints .sprint-info-line,
    .sprint-employees .sprint-info-line {
      height: 50px;
      margin-bottom: 8px; }
      .sprints .sprint-info-line__label,
      .sprint-employees .sprint-info-line__label {
        font-size: 12px;
        margin-bottom: 10px;
        font-family: "HelveticaNeueRegular";
        color: #9b9b9b;
        line-height: 16px;
        opacity: 0.4; }
      .sprints .sprint-info-line__value,
      .sprint-employees .sprint-info-line__value {
        font-size: 20px;
        line-height: 23px;
        font-family: "HelveticaNeueRegular";
        color: #9b9b9b; }
        .sprints .sprint-info-line__value.large,
        .sprint-employees .sprint-info-line__value.large {
          line-height: 27px; }
        .sprints .sprint-info-line__value.bold,
        .sprint-employees .sprint-info-line__value.bold {
          font-family: "HelveticaNeueMedium"; }
      .sprints .sprint-info-line .black,
      .sprint-employees .sprint-info-line .black {
        font-size: 29px;
        font-family: HelveticaNeueMedium;
        color: rgba(0, 0, 0, 0.6); }
      .sprints .sprint-info-line .logged-time,
      .sprint-employees .sprint-info-line .logged-time {
        font-size: 33px; }
  .sprints-admin-header > a,
  .sprint-employees-admin-header > a {
    margin-left: 2%; }
  .sprints-admin-header .admin-sprints-employees-list,
  .sprint-employees-admin-header .admin-sprints-employees-list {
    overflow: auto;
    white-space: nowrap; }
    .sprints-admin-header .admin-sprints-employees-list-item,
    .sprint-employees-admin-header .admin-sprints-employees-list-item {
      background-color: #fff;
      border: solid 1px #e2e2e2;
      padding: 10px;
      margin: 0 10px;
      opacity: 0.5; }
      .sprints-admin-header .admin-sprints-employees-list-item.active,
      .sprint-employees-admin-header .admin-sprints-employees-list-item.active {
        opacity: 1;
        border: 2px solid #e2e2e2; }
      .sprints-admin-header .admin-sprints-employees-list-item-user,
      .sprint-employees-admin-header .admin-sprints-employees-list-item-user {
        font-size: 22px;
        color: #000;
        line-height: 24px;
        margin-left: 10px; }
        .sprints-admin-header .admin-sprints-employees-list-item-user__name,
        .sprint-employees-admin-header .admin-sprints-employees-list-item-user__name {
          font-family: "HelveticaNeueMedium";
          margin-top: 10px; }
        .sprints-admin-header .admin-sprints-employees-list-item-user__surname,
        .sprint-employees-admin-header .admin-sprints-employees-list-item-user__surname {
          font-family: "HelveticaNeueMedium"; }
        .sprints-admin-header .admin-sprints-employees-list-item-user__position,
        .sprint-employees-admin-header .admin-sprints-employees-list-item-user__position {
          font-size: 14px;
          font-family: "HelveticaNeueRegular";
          color: #a6a6a6; }

.line {
  height: 2px;
  margin: 15px auto;
  width: 95%;
  background: lightgrey; }

.sprint-employees {
  justify-content: space-around; }
  .sprint-employees-item {
    width: 365px; }
    .sprint-employees-item-image img {
      width: 67px;
      height: 67px; }
    .sprint-employees-item-info {
      padding: 10px 0; }
      .sprint-employees-item-info .sprint-salary-info .sprint-info-line {
        white-space: nowrap; }
    .sprint-employees-item-user {
      font-size: 22px;
      color: #000;
      line-height: 24px;
      margin-left: 10px; }
      .sprint-employees-item-user__name {
        font-family: "HelveticaNeueMedium";
        font-size: 27px;
        white-space: nowrap;
        margin-top: 10px; }
      .sprint-employees-item-user__surname {
        font-family: "HelveticaNeueMedium"; }
      .sprint-employees-item-user__position {
        font-size: 14px;
        font-family: "HelveticaNeueRegular";
        color: #a6a6a6; }

.sprints-list {
  margin: 2px;
  overflow: auto;
  white-space: nowrap; }
  .sprints-list-item {
    box-sizing: border-box;
    background: #fff;
    padding: 10px;
    margin: 5px 10px;
    opacity: 0.5;
    font-family: "HelveticaNeueRegular";
    font-size: 32px;
    color: #9b9b9b; }
    .sprints-list-item > span {
      font-family: "HelveticaNeueMedium"; }
    .sprints-list-item.active {
      opacity: 1;
      color: #4a90e2;
      border: 2px #9b9b9b solid !important; }

.sprint-qcoefficient-value {
  color: #fff;
  font-size: 18px; }
  .sprint-qcoefficient-value.bold {
    color: #d0011b; }

.ruble {
  background: url("../images/ruble.svg") no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  background-position: right bottom;
  display: inline-block;
  height: 21px;
  width: 18px;
  opacity: 0.85; }

.ruble-small {
  height: 15px;
  width: 13px;
  opacity: 0.6; }

.personal-sprint .ruble {
  height: 21px;
  width: 18px; }

.personal-sprint .ruble-small {
  height: 15px;
  width: 13px; }

.personal-sprint .sprint-list {
  margin: 4px 15px 0 15px;
  padding-right: 15px;
  min-width: 360px; }
  .personal-sprint .sprint-list .sprint {
    margin: 0 0 10px 0 !important; }
    .personal-sprint .sprint-list .sprint .black {
      white-space: nowrap !important; }
    .personal-sprint .sprint-list .sprint .sprint-date {
      margin: 4px 0;
      white-space: nowrap; }

.personal-sprint .sprint-issues {
  flex-grow: 1.2;
  padding-right: 0 !important; }

.personal-sprint .sprint-worklogs {
  margin: 0 15px;
  flex-grow: 3; }
  .personal-sprint .sprint-worklogs .sprint-day-info__mobile_format_dow_date {
    display: none; }

.sprint-issues-hours {
  justify-content: space-between; }
  .sprint-issues-hours .sprint-info-line__value {
    font-size: 25px;
    white-space: nowrap; }

.sprint-day-worklog-item__link {
  color: #4a90e2;
  white-space: nowrap !important; }

.sprint-employees .sprint-employees-item {
  margin: 15px 7px !important; }
  .sprint-employees .sprint-employees-item .sprint-employees-item-user__name {
    white-space: inherit; }

.sprint-day-worklog-item {
  position: relative;
  margin-left: 5px !important;
  margin-right: 6px !important; }

.sprint-day-info__start-time {
  margin-bottom: 4px; }

.sprint-day {
  padding: 18px 15px; }

.productivity-issues .sprint-day {
  padding-left: 5px !important; }
