.productivity {
  padding-top: 20px; }
  .productivity .row {
    overflow: hidden; }
  .productivity .user,
  .productivity .issue {
    background: #fff;
    padding: 10px;
    margin: 10px 0;
    min-height: 120px; }
  .productivity .user-work-status {
    left: auto;
    right: -54.5px;
    z-index: 2;
    top: 25px;
    width: 109px;
    height: 109px; }
    .productivity .user-work-status .start-time,
    .productivity .user-work-status .current-time,
    .productivity .user-work-status .end-time {
      color: #fff;
      font-family: "HelveticaNeueMedium"; }
    .productivity .user-work-status .start-time,
    .productivity .user-work-status .end-time {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
      margin: 2px; }
    .productivity .user-work-status .start-time:before {
      top: 5px;
      left: 47px;
      width: 10px;
      height: 10px; }
    .productivity .user-work-status .end-time:after {
      width: 10px;
      height: 10px;
      bottom: 5px;
      left: 47px; }
    .productivity .user-work-status .current-time {
      font-size: 34px;
      line-height: 26px;
      font-weight: bold; }
    .productivity .user-work-status .worked-hrs,
    .productivity .user-work-status .current-date {
      display: none; }
  .productivity .user-info {
    font-size: 22px;
    color: #000;
    line-height: 24px;
    margin-left: 10px; }
    .productivity .user-info__name {
      font-family: "HelveticaNeueMedium";
      margin-top: 10px; }
    .productivity .user-info__surname {
      font-family: "HelveticaNeueMedium"; }
    .productivity .user-info__position {
      font-size: 14px;
      font-family: "HelveticaNeueRegular";
      color: #a6a6a6; }
  .productivity .user-avatar {
    width: 78px;
    height: 78px; }
    .productivity .user-avatar img {
      width: 78px;
      height: 78px; }
  .productivity .sprint-info {
    font-size: 13px;
    color: #9b9b9b;
    text-align: right;
    padding-top: 10px;
    position: relative;
    top: 14px;
    left: -5px;
    margin-right: 35px; }
    .productivity .sprint-info__title {
      font-family: "HelveticaNeueRegular"; }
    .productivity .sprint-info__current-time {
      font-size: 27px;
      font-family: "HelveticaNeueMedium";
      color: #000;
      line-height: 32px; }
    .productivity .sprint-info__hours {
      font-family: "HelveticaNeueRegular"; }
  .productivity .jira-links a {
    color: #4a90e2;
    margin: 10px 20px 10px 10px;
    text-decoration: underline; }
  .productivity .issue {
    position: relative; }
    .productivity .issue .c2 {
      min-width: 40px;
      font-size: 15px;
      font-family: "HelveticaNeueRegular";
      color: #4990e2; }
    .productivity .issue .c3 {
      margin-left: 5px;
      justify-content: space-between; }
      .productivity .issue .c3 .defaul,
      .productivity .issue .c3 .fill {
        height: 30px;
        width: 30px;
        position: relative;
        right: -2px; }
        .productivity .issue .c3 .defaul i,
        .productivity .issue .c3 .fill i {
          padding-top: 9px;
          padding-left: 1px;
          height: 30px;
          width: 30px; }
      .productivity .issue .c3 .fill {
        background-color: #01bc65;
        background-image: url("../images/check.svg");
        -webkit-background-size: 70% 70%;
        background-size: 70% 70%;
        background-position: 53% 53%;
        background-repeat: no-repeat; }
        .productivity .issue .c3 .fill i {
          background-color: rgba(0, 0, 0, 0); }
      .productivity .issue .c3 .defaul {
        background-color: white;
        font-size: 10px; }
    .productivity .issue-hours {
      font-size: 15px;
      font-family: "HelveticaNeueMedium";
      color: #d0011b;
      white-space: nowrap; }
    .productivity .issue-buttons > div {
      position: relative;
      width: 24px;
      height: 24px;
      border: 1px solid #979797;
      text-align: center;
      font-size: 14px;
      color: #979797;
      font-weight: 100;
      cursor: pointer; }
    .productivity .issue-buttons .fill {
      background-color: #01BC65;
      border-color: #01BC65;
      color: #fff; }
    .productivity .issue-buttons .success {
      border-color: #01BC65;
      color: #01BC65;
      height: 30px;
      width: 31px;
      position: relative; }
      .productivity .issue-buttons .success i {
        margin-right: 3px;
        position: absolute;
        top: 7px;
        bottom: 0; }
    .productivity .issue-buttons .primary {
      border-color: #4990e2;
      color: #4990e2;
      height: 30px;
      width: 31px; }
    .productivity .issue-buttons .success,
    .productivity .issue-buttons .primary {
      position: relative; }
    .productivity .issue-buttons .fa-play {
      position: absolute;
      right: 4px; }
    .productivity .issue-title {
      font-size: 15px;
      font-weight: 700;
      font-family: "HelveticaNeueRegular";
      margin-top: 10px;
      line-height: 16px;
      color: #000; }
    .productivity .issue .components-label {
      font-size: 15px;
      font-family: "HelveticaNeueThinItalic";
      margin-top: 10px;
      color: #9b9b9b; }
    .productivity .issue-info > img {
      width: 16px;
      height: 16px; }
    .productivity .issue-info > a {
      margin: 0 5px;
      white-space: nowrap; }
    .productivity .issue-info > .badge {
      font-size: 10px;
      font-family: HelveticaNeueRegular;
      text-transform: uppercase;
      line-height: 13px;
      padding-right: 18px;
      padding-left: 18px;
      min-width: 74px;
      border-radius: 10px;
      height: 16px; }
  .productivity .section {
    margin-top: 25px; }
    .productivity .section-header {
      font-size: 24px;
      color: #9b9b9b;
      font-family: "HelveticaNeueRegular";
      margin-bottom: 5px; }
      .productivity .section-header span {
        font-size: 24px;
        color: #000;
        margin: 0 5px; }
      .productivity .section-header .badge {
        font-size: 12px;
        font-family: "HelveticaNeueBold";
        line-height: 15px;
        font-weight: 100;
        height: 20px;
        padding-left: 6px;
        vertical-align: middle;
        margin-right: 5px;
        width: 25px;
        text-align: center;
        background-color: #9b9b9b;
        border-radius: 16px; }
    .productivity .section-content {
      background-color: #fff; }
      .productivity .section-content.scrolled {
        max-height: 450px;
        overflow-y: scroll;
        padding-right: 10px; }
      .productivity .section-content.empty {
        background-color: #e8e7e8; }
  .productivity .sprint-day {
    border-bottom: 1px solid #e3e3e3;
    padding: 10px 0; }
    .productivity .sprint-day-info {
      border-right: 1px solid #e3e3e3;
      padding: 0 7px;
      padding-right: 15px; }
      .productivity .sprint-day-info__dow {
        font-size: 15px;
        line-height: 16px;
        color: #4a4a4a;
        font-family: "HelveticaNeueBold";
        text-transform: capitalize; }
      .productivity .sprint-day-info__date {
        font-size: 15px;
        font-family: "HelveticaNeueRegular";
        line-height: 16px;
        color: #9b9b9b; }
      .productivity .sprint-day-info__mobile_format_dow_date {
        display: none; }
      .productivity .sprint-day-info__start-time {
        position: relative;
        margin-top: 10px;
        font-size: 14px;
        line-height: 14px;
        color: #9b9b9b;
        font-family: "HelveticaNeueBold"; }
        .productivity .sprint-day-info__start-time:after {
          top: 15px;
          left: 12px;
          position: absolute; }
      .productivity .sprint-day-info__end-time {
        font-size: 14px;
        font-family: "HelveticaNeueBold";
        line-height: 14px;
        color: #9b9b9b; }
      .productivity .sprint-day-info__duration {
        font-size: 18px;
        font-family: "HelveticaNeueBold";
        line-height: 18px;
        color: #d0011b;
        padding-left: 50px;
        font-weight: 600; }
        .productivity .sprint-day-info__duration.success {
          color: #01BC65; }
    .productivity .sprint-day-worklog {
      margin-left: 10px; }
      .productivity .sprint-day-worklog-time {
        font-size: 14px;
        font-family: "HelveticaNeueBold";
        color: #4990e2; }
      .productivity .sprint-day-worklog-item > img {
        width: 10px;
        height: 10px;
        margin: 0 5px; }
      .productivity .sprint-day-worklog-item__link {
        font-size: 11px;
        font-family: "HelveticaNeueBold";
        min-width: 60px; }
      .productivity .sprint-day-worklog-item__duration {
        cursor: pointer;
        font-size: 12px;
        font-family: "HelveticaNeueRegular";
        color: #9b9b9b; }
      .productivity .sprint-day-worklog-item__title {
        font-size: 10px;
        font-family: "HelveticaNeueMedium";
        color: #000; }
        @media (min-width: 992px) {
          .productivity .sprint-day-worklog-item__title {
            max-width: 276px; } }
      .productivity .sprint-day-worklog-item__delete {
        margin-left: 5px;
        margin-right: 10px;
        font-size: 10px;
        line-height: 12px;
        cursor: pointer; }
        .productivity .sprint-day-worklog-item__delete a {
          margin: 0; }
  .productivity .sprint-feature {
    padding: 15px;
    border-bottom: 1px #e3e3e3 solid; }
    .productivity .sprint-feature-title {
      font-size: 19px;
      font-family: "HelveticaNeueBold";
      color: #000;
      margin: 5px 0; }
    .productivity .sprint-feature-components-label {
      font-size: 15px;
      color: #9b9b9b;
      font-family: "HelveticaNeueThinItalic"; }
    .productivity .sprint-feature-info > a {
      font-size: 18px;
      font-family: "HelveticaNeueBold";
      color: #4990e2;
      margin: 0 10px; }
    .productivity .sprint-feature-info > img {
      width: 16px;
      height: 16px; }
    .productivity .sprint-feature-info .badge {
      width: 30px;
      border-radius: 10px; }
    .productivity .sprint-feature-info .add-subtask {
      font-size: 14px;
      font-family: "HelveticaNeueRegular";
      color: #4990e2;
      text-decoration: underline;
      margin: 0 10px; }

.productivity .issue.issue-in-work .issue-info > .badge {
  background-color: #01BC65; }

.productivity .issue.issue-pause .issue-info > .badge {
  background-color: #9b9b9b; }

.productivity .issue.issue-planned .issue-info > .badge {
  background-color: #4990e2; }

.productivity .paused .issue,
.productivity .planned .issue,
.productivity .unplanned .issue {
  border-bottom: 1px solid #e3e3e3; }

.productivity .paused .issue .c1,
.productivity .unplanned .issue .c1,
.productivity .planned .issue .c1 {
  margin-left: 15px; }

.productivity .planned .issue .issue-hours,
.productivity .unplanned .issue .issue-hours {
  display: none; }

.productivity .planned .section-header .badge,
.productivity .unplanned .section-header .badge {
  background-color: #4990e2; }

.productivity .planned .issue .issue-buttons > div,
.productivity .unplanned .issue .issue-buttons > div {
  margin-right: 7px; }

@media only screen and (max-width: 992px) {
  .issue-buttons {
    margin-bottom: 30px; } }

.main-productivity-block {
  display: flex; }
  .main-productivity-block > div {
    display: flex; }
    .main-productivity-block > div > div {
      width: 100%;
      padding-top: 16px;
      padding-bottom: 16px; }
