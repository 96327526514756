input:invalid {
  box-shadow: none; }

.employee-card {
  padding: 20px;
  width: 60%; }
  .employee-card .card .card-header {
    background-color: #4990e2; }
    .employee-card .card .card-header img {
      width: 50px;
      height: 50px;
      margin-right: 15px; }
  .employee-card .card .card-block {
    background-color: #f8f8f8; }
    .employee-card .card .card-block ul {
      margin-bottom: 20px; }
    .employee-card .card .card-block .collapse-label {
      color: #4990e2;
      cursor: pointer; }
    .employee-card .card .card-block .list-group-item input {
      border: none;
      cursor: pointer; }
      .employee-card .card .card-block .list-group-item input:focus {
        cursor: text; }
  .employee-card .card .card-footer {
    background-color: #e7e7e7; }
  .employee-card .ReactCollapse--collapse {
    width: 100%;
    margin-top: 10px; }
    .employee-card .ReactCollapse--collapse .row {
      margin: 0; }

.personal {
  max-width: 940px;
  padding-top: 40px; }
  .personal .section-header {
    margin: 0px 10px;
    font-size: 24px;
    color: #9b9b9b;
    font-family: "HelveticaNeueRegular";
    margin-bottom: 5px; }
  .personal .section-content {
    margin: 10px; }
    .personal .section-content .employee-form {
      margin-right: 1px; }
    .personal .section-content .col-4 {
      padding-left: 21px; }
    .personal .section-content .col-5 {
      margin-left: -21px; }
    .personal .section-content .dropdown-menu-component {
      background-color: #EBEBEB;
      width: 130px;
      height: 34px;
      -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2) inset;
      -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2) inset;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2) inset;
      border-radius: 5px; }
      .personal .section-content .dropdown-menu-component button {
        background-color: rgba(0, 0, 0, 0);
        display: inline-flex;
        display: -webkit-inline-flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        height: 100%; }
        .personal .section-content .dropdown-menu-component button :first-child {
          margin-top: 5px; }
        .personal .section-content .dropdown-menu-component button :last-child {
          padding-top: 10px;
          font-size: 8px; }
      .personal .section-content .dropdown-menu-component .dropdown-menu {
        min-width: 0;
        padding: 10px 13px;
        border: none;
        font-size: 14px;
        -webkit-box-shadow: 0 4px 10px grey;
        -moz-box-shadow: 0 4px 10px grey;
        box-shadow: 0 4px 10px grey;
        font-family: Arial; }
        .personal .section-content .dropdown-menu-component .dropdown-menu li {
          margin-top: 5px; }
  .personal-info {
    border-right: solid 1px lightgray;
    font-family: "HelveticaNeueRegular"; }
    .personal-info-position {
      font-size: 20px;
      color: #161616;
      font-family: "HelveticaNeueMedium"; }
    .personal-info-status {
      font-size: 14px;
      color: #4A4A4A;
      margin: 10px 0; }
    .personal-info-date {
      font-size: 14px;
      color: #4A4A4A; }
    .personal-info .salary {
      margin-bottom: 10px; }
      .personal-info .salary-title {
        font-size: 13px;
        color: #a6a6a6; }
      .personal-info .salary-value {
        font-size: 27px;
        color: #4a4a4a;
        line-height: 22px;
        font-family: "HelveticaNeueMedium";
        font-weight: lighter; }
  .personal-documents {
    font-family: "HelveticaNeueRegular"; }
    .personal-documents .section-content {
      padding: 20px;
      background-color: #fff;
      min-height: 50px;
      border: solid 1px #E2E2E2;
      margin-bottom: 20px;
      position: relative; }
      .personal-documents .section-content button[type=submit] {
        color: white;
        font-family: HelveticaNeueRegular;
        font-size: 14px;
        font-weight: 100;
        padding-top: 8px;
        width: 172px;
        height: 34px; }
    .personal-documents .new-document button {
      font-size: 14px;
      border-radius: 5px;
      font-family: "HelveticaNeueRegular"; }
    .personal-documents .new-document select {
      font-size: 14px;
      background-color: #EBEBEB;
      -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2) inset;
      -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2) inset;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2) inset; }
      .personal-documents .new-document select:active, .personal-documents .new-document select:focus {
        outline: none; }
    .personal-documents .new-document textarea::-webkit-input-placeholder {
      color: rgba(155, 155, 155, 0.38);
      font-size: 14px;
      font-family: "HelveticaNeueRegular"; }
    .personal-documents .new-document textarea:-moz-placeholder {
      color: #9B9B9B;
      font-size: 14px;
      font-family: "HelveticaNeueRegular";
      opacity: 1; }
    .personal-documents .new-document textarea::-moz-placeholder {
      color: #9B9B9B;
      font-size: 14px;
      font-family: "HelveticaNeueRegular";
      opacity: 1; }
    .personal-documents .new-document textarea:-ms-input-placeholder {
      color: #9B9B9B;
      font-size: 14px;
      font-family: "HelveticaNeueRegular"; }
    .personal-documents .new-document .attachment-curtain {
      background-color: #FCFCFC;
      border: #F9F9F9 solid 1px;
      text-align: center;
      font-size: 12px;
      font-family: "HelveticaNeueRegular";
      margin-bottom: 20px;
      padding: 20px;
      color: #D9D9D9; }
      .personal-documents .new-document .attachment-curtain input[type=file] {
        display: none; }
      .personal-documents .new-document .attachment-curtain.is-dragover {
        background-color: #fff;
        outline: 2px dashed #92b0b3 !important; }
    .personal-documents .new-document .attachment-preview {
      width: 100%;
      max-height: 400px; }
      .personal-documents .new-document .attachment-preview-screencover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        box-sizing: border-box;
        z-index: 2;
        opacity: 0;
        background-color: #000; }
      .personal-documents .new-document .attachment-preview-wrapper {
        width: 100%;
        margin-bottom: 20px;
        position: relative; }
        .personal-documents .new-document .attachment-preview-wrapper i {
          font-family: 'FontAwesome';
          font-style: normal; }
        .personal-documents .new-document .attachment-preview-wrapper:hover .attachment-preview-screencover {
          opacity: 0.6; }
        .personal-documents .new-document .attachment-preview-wrapper:hover .attachment-preview__remove {
          opacity: 1; }
      .personal-documents .new-document .attachment-preview__remove {
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        opacity: 0;
        z-index: 10;
        color: white;
        font-size: 35px;
        cursor: pointer; }
    .personal-documents .document-panel {
      padding-right: 0; }
    .personal-documents .document i {
      font-family: 'FontAwesome';
      font-style: normal; }
    .personal-documents .document .save-options button {
      background: #f5f5f5;
      border: 1px solid #ccc;
      border-radius: 3.01px;
      cursor: pointer;
      font-size: 14px;
      height: 24px;
      width: 24px;
      padding: 0;
      margin: 3px; }
      .personal-documents .document .save-options button:hover {
        background: #e9e9e9;
        border-color: #999;
        color: #000; }
    .personal-documents .document .attachment-preview {
      width: 100%;
      max-height: 400px; }
      .personal-documents .document .attachment-preview-screencover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        box-sizing: border-box;
        z-index: 2;
        opacity: 0;
        background-color: #000; }
      .personal-documents .document .attachment-preview-wrapper {
        width: 100%;
        margin-bottom: 20px;
        position: relative; }
        .personal-documents .document .attachment-preview-wrapper i {
          font-family: 'FontAwesome';
          font-style: normal; }
        .personal-documents .document .attachment-preview-wrapper:hover .attachment-preview-screencover {
          opacity: 0.6; }
        .personal-documents .document .attachment-preview-wrapper:hover .attachment-preview__remove {
          opacity: 1; }
      .personal-documents .document .attachment-preview__remove {
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 14px);
        opacity: 0;
        z-index: 10;
        color: white;
        font-size: 35px;
        cursor: pointer; }
    .personal-documents .document-type {
      font-family: "HelveticaNeueBold";
      background-color: #5BC0DE;
      color: #FDFEFE;
      font-size: 14px;
      border-radius: 3px;
      padding: 5px;
      text-align: center;
      margin-bottom: 10px; }
    .personal-documents .document-photo-wrap {
      width: 100%; }
      .personal-documents .document-photo-wrap img {
        width: 100%; }
    .personal-documents .document-content {
      font-size: 16px;
      position: relative;
      color: 4A4A4A; }
      .personal-documents .document-content .overlay-icon {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        opacity: 0;
        background-color: #f0f0f0;
        background-repeat: no-repeat;
        background-position: 4px 4px;
        border-left: 1px solid #ccc;
        bottom: 0;
        height: 100%;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        z-index: -1; }
        .personal-documents .document-content .overlay-icon i {
          left: 5px;
          top: 5px;
          position: absolute; }
      .personal-documents .document-content:hover {
        padding: 5px 25px 5px 5px;
        box-shadow: 0 0 0 1px #ccc; }
        .personal-documents .document-content:hover .overlay-icon {
          opacity: 1;
          z-index: 1; }
    .personal-documents .document-delete {
      font-family: "HelveticaNeueBold";
      position: absolute;
      color: red;
      top: 5px;
      right: 10px;
      font-size: 15px;
      cursor: pointer; }
    .personal-documents .document-attachment-curtain {
      font-size: 12px;
      background-color: #FCFCFC;
      border: solid 1px #F9F9F9;
      padding: 10px;
      text-align: center;
      color: #DDDDDD;
      margin: 10px 0 20px 0; }
      .personal-documents .document-attachment-curtain input[type=file] {
        display: none; }
      .personal-documents .document-attachment-curtain.is-dragover {
        background-color: #fff;
        outline: 2px dashed #92b0b3 !important; }
