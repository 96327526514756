button {
  cursor: pointer; }

.vacations {
  margin-top: 40px;
  margin-bottom: 30px;
  padding: 0px 80px !important; }
  .vacations .hospital-section {
    min-height: 500px; }
  .vacations .section-header {
    margin: 0 10px;
    cursor: default;
    font-size: 24px;
    color: #9b9b9b;
    font-family: "HelveticaNeueRegular";
    margin-bottom: 5px; }
    .vacations .section-header span {
      font-size: 24px;
      color: #000;
      margin: 0 5px; }
    .vacations .section-header .badge {
      padding-top: 4px;
      padding-bottom: 2px;
      padding-left: 9px;
      padding-right: 9px;
      font-size: 14px;
      font-family: "HelveticaNeueBold";
      line-height: 15px;
      font-weight: 100;
      height: 20px;
      vertical-align: middle;
      margin-right: 5px;
      text-align: center;
      border-radius: 16px;
      border-radius: 10rem; }
  .vacations .section-content {
    position: relative;
    padding: 20px;
    padding-right: 5px;
    background-color: #fff;
    min-height: 50px;
    border: solid 1px #e2e2e2;
    margin: 10px;
    margin-bottom: 20px; }
    .vacations .section-content select {
      border: rgba(0, 0, 0, 0.15) solid 1px;
      border-radius: 4px;
      padding: 8px 12px; }
    .vacations .section-content__inactive {
      opacity: 0.4; }
    .vacations .section-content p {
      font-size: 18px;
      font-family: "HelveticaNeueRegular";
      cursor: default; }
    .vacations .section-content form {
      padding: 0 20px; }
      .vacations .section-content form label,
      .vacations .section-content form small {
        font-size: 14px;
        font-family: "HelveticaNeueRegular";
        color: #9b9b9b; }
      .vacations .section-content form small {
        margin: auto 0;
        cursor: default;
        margin-left: 15px; }
      .vacations .section-content form b {
        font-family: "HelveticaNeueBold"; }
      .vacations .section-content form textarea::-webkit-input-placeholder {
        color: rgba(155, 155, 155, 0.38);
        font-size: 14px;
        font-family: "HelveticaNeueRegular"; }
      .vacations .section-content form textarea:-moz-placeholder {
        color: rgba(155, 155, 155, 0.38);
        font-size: 14px;
        font-family: "HelveticaNeueRegular";
        opacity: 1; }
      .vacations .section-content form textarea::-moz-placeholder {
        color: rgba(155, 155, 155, 0.38);
        font-size: 14px;
        font-family: "HelveticaNeueRegular";
        opacity: 1; }
      .vacations .section-content form textarea:-ms-input-placeholder {
        color: rgba(155, 155, 155, 0.38);
        font-size: 14px;
        font-family: "HelveticaNeueRegular"; }
    .vacations .section-content .vacation-info {
      padding: 0 10px; }
      .vacations .section-content .vacation-info div {
        margin: 10px 0;
        cursor: default;
        font-size: 16px;
        font-family: "HelveticaNeueRegular"; }
        .vacations .section-content .vacation-info div.sick-leave-info-reason {
          margin: 0;
          text-decoration: underline;
          cursor: pointer; }
      .vacations .section-content .vacation-info b {
        font-family: "HelveticaNeueBold"; }
    .vacations .section-content .vacation-dates {
      margin: 0 0 10px 0 !important;
      color: #4a4a4a;
      font-size: 16px;
      font-family: "HelveticaNeueRegular"; }
      .vacations .section-content .vacation-dates b {
        font-family: "HelveticaNeueBold"; }
    .vacations .section-content .sick-leave-info {
      padding: 0 20px 0 10px;
      width: 100%; }
      .vacations .section-content .sick-leave-info-field {
        cursor: default;
        margin: 10px 0;
        font-size: 16px;
        font-family: "HelveticaNeueRegular"; }
      .vacations .section-content .sick-leave-info div:first-child {
        margin: 0; }
      .vacations .section-content .sick-leave-info b {
        font-family: "HelveticaNeueBold"; }
    .vacations .section-content .sick-leave-attachment-curtain {
      height: 42px;
      background: #d8d8d8;
      opacity: 0.3;
      font-size: 12px;
      width: 100%;
      margin: 15px 0;
      padding: 10px 15px; }
      .vacations .section-content .sick-leave-attachment-curtain input[type="file"] {
        display: none; }
      .vacations .section-content .sick-leave-attachment-curtain.is-dragover {
        background-color: #fff;
        outline: 2px dashed #92b0b3 !important; }
    .vacations .section-content .attachment-preview {
      width: 100%;
      max-height: 400px; }
      .vacations .section-content .attachment-preview-screencover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        box-sizing: border-box;
        z-index: 2;
        opacity: 0;
        background-color: #000; }
      .vacations .section-content .attachment-preview-wrapper {
        width: 100%;
        margin-bottom: 20px;
        position: relative; }
        .vacations .section-content .attachment-preview-wrapper i {
          font-family: "FontAwesome";
          font-style: normal; }
        .vacations .section-content .attachment-preview-wrapper:hover .attachment-preview-screencover {
          opacity: 0.6; }
        .vacations .section-content .attachment-preview-wrapper:hover .attachment-preview__remove {
          opacity: 1; }
      .vacations .section-content .attachment-preview__remove {
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        opacity: 0;
        z-index: 10;
        color: white;
        font-size: 35px;
        cursor: pointer; }
    .vacations .section-content .light {
      line-height: 13px;
      color: #9b9b9b;
      font-size: 15px;
      font-family: "HelveticaNeueRegular";
      margin: 0;
      cursor: default; }

.rdtDays {
  width: 100%; }
  .rdtDays td {
    cursor: pointer; }
  .rdtDays .rdtPrev span,
  .rdtDays .rdtNext span {
    font-weight: bold; }
  .rdtDays table {
    width: 100%; }
    .rdtDays table th,
    .rdtDays table td {
      text-align: center;
      line-height: 34px; }
  .rdtDays .rdtTimeToggle {
    display: none; }

.rdt input {
  display: none; }

.control-dates {
  display: flex;
  flex-direction: column;
  font-size: 12px; }
  .control-dates :first-child {
    color: #428cca; }
  .control-dates :last-child {
    color: #f5a623;
    padding-left: 11px; }

.control-dates-container {
  justify-content: space-between; }
  .control-dates-container button {
    width: 105px;
    height: 34px;
    font-size: 14px;
    font-family: HelveticaNeueRegular; }

.rdtPrev,
.rdtNext {
  cursor: pointer; }

th.dow,
.rdtSwitch {
  cursor: default; }

.endDay {
  background-color: #9b9b9b;
  color: white; }

.workDay {
  background-color: #f5a623;
  color: white; }

.rdtActive {
  background-color: #0275d8;
  color: white; }

.react-date-picker__button {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 12px;
  width: 100%;
  height: 42px; }
  .react-date-picker__button button {
    opacity: 0.6; }
  .react-date-picker__button__input__year {
    color: #464a4c; }
  .react-date-picker__button__input {
    height: 24px; }

.react-date-picker__calendar {
  z-index: 9; }

.no-vacation-days-message {
  color: #9b9b9b;
  font-family: "HelveticaNeueRegular";
  font-size: 14px; }

.border-right {
  border-right: lightgray solid 1px; }

.hospital-submit-button {
  background-color: #f5a623;
  height: 34px;
  width: 172px;
  font-family: HelveticaNeueRegular;
  font-size: 14px !important;
  color: white; }

.dropdown-menu-component {
  background-color: #ebebeb;
  width: 90px;
  height: 34px;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2) inset;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: relative; }
  .dropdown-menu-component button {
    background-color: rgba(0, 0, 0, 0);
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border: none; }
    .dropdown-menu-component button :first-child {
      margin-top: 5px; }
    .dropdown-menu-component button :last-child {
      padding-top: 10px;
      font-size: 8px; }
  .dropdown-menu-component button:focus {
    outline: none; }
  .dropdown-menu-component .dropdown-menu {
    position: absolute;
    width: 100%;
    min-width: 0;
    padding: 10px 0px !important;
    border: none;
    font-size: 14px;
    box-sizing: border-box;
    -webkit-box-shadow: 0 4px 10px grey;
    -moz-box-shadow: 0 4px 10px grey;
    box-shadow: 0 4px 10px grey;
    font-family: Arial; }
    .dropdown-menu-component .dropdown-menu ul {
      top: 0;
      display: none;
      left: 0;
      width: 90px; }
    .dropdown-menu-component .dropdown-menu li {
      padding: 5px 13px; }
    .dropdown-menu-component .dropdown-menu li:hover {
      background-color: #eceeef; }

.close-hospital-button {
  background-color: #059419;
  height: 34px;
  width: 172px;
  font-family: HelveticaNeueRegular;
  font-size: 14px !important;
  color: white;
  margin-right: 20px; }

.add-hospital-start-date {
  display: flex;
  justify-content: space-between;
  margin-left: 5px; }
  .add-hospital-start-date label {
    margin-top: 7px; }
  .add-hospital-start-date label {
    width: 20px; }
  .add-hospital-start-date .data-picker {
    width: 90%; }

.rdtMonths,
.rdtYears {
  height: 288px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .rdtMonths td,
  .rdtYears td {
    padding: 10px; }
