.employee-form {
  margin: 20px 0;
  padding: 25px 42px;
  background-color: #fff;
  position: relative; }
  .employee-form button {
    font-size: 14px;
    width: 105px;
    height: 30px;
    font-family: "HelveticaNeueRegular"; }
