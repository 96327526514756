.login-page-wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background-color: #e7e7e7; }

.login-page {
  position: absolute;
  top: calc(50% - 173px);
  width: 570px;
  height: 360px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff; }
  .login-page > div:first-child {
    margin: 17px 30px; }
    .login-page > div:first-child .logo {
      margin-top: 40px; }
  .login-page > div:nth-child(2) {
    margin: 60px 30px 15px 10px; }
  .login-page .software-development {
    margin-top: 24px;
    line-height: 23px;
    font-size: 20px;
    font-family: "HelveticaNeueRegular";
    font-weight: 100;
    color: #9b9b9b; }
  .login-page .link {
    margin-top: 20px;
    margin-bottom: 32px !important;
    color: #0071b9;
    font-size: 14px;
    font-family: "HelveticaNeueRegular";
    font-weight: 100;
    text-decoration: underline; }
  .login-page .soft-artel {
    color: #0071b9;
    font-size: 36px;
    font-family: "HelveticaNeueRegular";
    line-height: 40px;
    font-weight: 100; }
  .login-page .backoffice {
    color: #0072bb;
    font-size: 42px;
    font-family: "HelveticaNeueBold";
    line-height: 45px;
    font-weight: 600; }
  .login-page .accentLabel {
    color: #1a7bf9 !important; }
  .login-page .phone-number {
    display: flex;
    margin-top: 50px; }
    .login-page .phone-number .input-group-addon:not(:first-child):not(:last-child) {
      width: 47px;
      border-bottom-left-radius: 0.25rem;
      border-top-left-radius: 0.25rem; }
  .login-page .phone-number .country-select {
    display: block;
    position: relative;
    padding: 8px 5px 8px 0;
    max-width: 200px;
    color: #55acee; }
  .login-page .phone-number .country-select select {
    color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 90%;
    display: inline-block;
    margin: 0 0 10px;
    padding: 8px 10px;
    font-weight: 400;
    outline: none;
    background: 0 0;
    box-shadow: none;
    border-radius: 4px;
    appearance: none;
    border: none; }
  .login-page .phone-number .country-select select option {
    color: #000; }
  .login-page .phone-number .chevron {
    font-size: 14px;
    margin-left: 3px; }
  .login-page .phone-number .country-selected {
    font-size: 14px; }
  .login-page .phone-number .country-selected {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .login-page .phone-number .phone-input {
    display: inline-block;
    width: 100%;
    flex: 1; }
    .login-page .phone-number .phone-input input {
      display: inline-block;
      width: 171px;
      height: 43px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(182, 194, 203, 0.5);
      outline: none;
      background: 0 0;
      box-shadow: none;
      border-radius: 5px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      -webkit-appearance: none;
      border: 1px solid #b6c2cb;
      padding-top: 10px;
      font-family: "HelveticaNeueRegular"; }
    .login-page .phone-number .phone-input input[placeholder] {
      font-size: 18px;
      color: red;
      color: #000000;
      font-weight: 100;
      font-family: "HelveticaNeueRegular"; }
    .login-page .phone-number .phone-input input::-moz-placeholder {
      text-overflow: ellipsis; }
    .login-page .phone-number .phone-input input:-moz-placeholder {
      text-overflow: ellipsis; }
    .login-page .phone-number .phone-input input:-ms-input-placeholder {
      text-overflow: ellipsis; }
  .login-page .code-input {
    margin-top: 15px;
    width: 180px; }
    .login-page .code-input > span {
      height: 43px; }
    .login-page .code-input input {
      height: 43px;
      font-weight: 100; }
  .login-page .fa {
    font-size: 22px;
    color: #9b9b9b; }
  .login-page .error-message {
    bottom: -5px;
    position: absolute; }
  .login-page.code {
    top: calc(50% - 205px); }
    .login-page.code .link {
      margin-top: 25px; }
    .login-page.code .phone-number {
      margin-top: 38px; }
    .login-page.code .error-message {
      bottom: 5px; }

.input-after {
  position: absolute;
  right: -40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  top: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #464a4c; }

.phone-input .input-after {
  margin-top: 50px; }

.spinner {
  font-size: 20px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  top: 42px;
  left: -5px; }
  .spinner .center {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; }
  .spinner .spinner-blade {
    position: absolute;
    left: 0.4629em;
    bottom: 0;
    width: 0.1em;
    height: 0.3em;
    border-radius: 0.0555em;
    background-color: transparent;
    -webkit-transform-origin: center -0.2222em;
    -ms-transform-origin: center -0.2222em;
    transform-origin: center -0.2222em;
    -webkit-animation: spinner-fade 1s infinite linear;
    animation: spinner-fade 1s infinite linear; }
    .spinner .spinner-blade:nth-child(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg); }
    .spinner .spinner-blade:nth-child(2) {
      -webkit-animation-delay: 0.083s;
      animation-delay: 0.083s;
      -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      transform: rotate(30deg); }
    .spinner .spinner-blade:nth-child(3) {
      -webkit-animation-delay: 0.166s;
      animation-delay: 0.166s;
      -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      transform: rotate(60deg); }
    .spinner .spinner-blade:nth-child(4) {
      -webkit-animation-delay: 0.249s;
      animation-delay: 0.249s;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg); }
    .spinner .spinner-blade:nth-child(5) {
      -webkit-animation-delay: 0.332s;
      animation-delay: 0.332s;
      -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
      transform: rotate(120deg); }
    .spinner .spinner-blade:nth-child(6) {
      -webkit-animation-delay: 0.415s;
      animation-delay: 0.415s;
      -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
      transform: rotate(150deg); }
    .spinner .spinner-blade:nth-child(7) {
      -webkit-animation-delay: 0.498s;
      animation-delay: 0.498s;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg); }
    .spinner .spinner-blade:nth-child(8) {
      -webkit-animation-delay: 0.581s;
      animation-delay: 0.581s;
      -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
      transform: rotate(210deg); }
    .spinner .spinner-blade:nth-child(9) {
      -webkit-animation-delay: 0.664s;
      animation-delay: 0.664s;
      -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
      transform: rotate(240deg); }
    .spinner .spinner-blade:nth-child(10) {
      -webkit-animation-delay: 0.747s;
      animation-delay: 0.747s;
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg); }
    .spinner .spinner-blade:nth-child(11) {
      -webkit-animation-delay: 0.83s;
      animation-delay: 0.83s;
      -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
      transform: rotate(300deg); }
    .spinner .spinner-blade:nth-child(12) {
      -webkit-animation-delay: 0.913s;
      animation-delay: 0.913s;
      -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
      transform: rotate(330deg); }

@-webkit-keyframes spinner-fade {
  0% {
    background-color: #69717d; }
  100% {
    background-color: transparent; } }

@keyframes spinner-fade {
  0% {
    background-color: #69717d; }
  100% {
    background-color: transparent; } }
