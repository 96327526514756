/*==========  Desktop First Method  ==========*/
@media only screen and (max-width: 1230px) {
  .sprints .sprint-date {
    font-size: 30px; }
  .sprints .sprint-info-line .black {
    font-size: 28px; } }

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .sprint-issues {
    max-width: 54% !important; }
  .personal-sprint .sprint-list {
    margin-bottom: 10px !important; }
  .nav-link > div {
    padding: 0 20px !important; }
  .current-sprint-wrap {
    cursor: default;
    height: 60px;
    padding: 12px 0; }
    .current-sprint-wrap .current-sprint {
      min-width: 200px !important;
      padding: 2px 0px 2px 18px !important; }
  .personal-sprint .sprints {
    flex-wrap: wrap !important; }
    .personal-sprint .sprints .sprint-issues {
      margin-right: 15px; }
  .personal-sprint .current-sprint-wrap {
    cursor: default;
    height: 60px;
    padding: 12px 0; }
    .personal-sprint .current-sprint-wrap .current-sprint {
      min-width: 100px !important;
      padding: 2px 0px 2px 18px !important; }
  .personal-sprint .personal-sprint .ruble {
    height: 18px;
    width: 16px; }
  .personal-sprint .personal-sprint .ruble-small {
    height: 15px;
    width: 13px; }
  .personal-sprint .personal-sprint .sprints {
    flex-wrap: wrap !important;
    padding: 0;
    margin-left: 0;
    margin-right: 15px;
    margin-top: -3px; }
    .personal-sprint .personal-sprint .sprints .sprint-list {
      padding-right: 0;
      border-right: 0 !important;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 0px;
      width: auto !important;
      min-width: 0 !important; }
      .personal-sprint .personal-sprint .sprints .sprint-list .sprint .black {
        white-space: nowrap !important; }
      .personal-sprint .personal-sprint .sprints .sprint-list .sprint .sprint-date {
        white-space: nowrap; }
  .personal-sprint .personal-sprint .issue .c2 {
    min-width: 10px; }
  .personal-sprint .personal-sprint .sprint-info-line .black {
    font-size: 25px; }
  .personal-sprint .personal-sprint .sprint-worklogs {
    margin-right: 15px; }
  .employee-form {
    padding: 20px 25px; }
  .vacations {
    padding: 0 15px !important; } }

@media only screen and (min-width: 993px) {
  .productivity .last-issue-container .issue-in-work .c1, .productivity .last-issue-container .issue-pause .c1 {
    margin-left: 42px !important; }
  .productivity .issue-buttons > div {
    margin: 0 5px; } }

@media screen and (max-width: 1091px) {
  .sprint-issues {
    max-width: 50% !important; } }

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .productivity {
    padding-top: 0 !important; }
  .user-info-container, .last-issue-container {
    max-width: 100% !important; }
  .productivity-issues .section-content {
    overflow: hidden !important; }
  .productivity-issues > div {
    max-width: 100% !important; }
  .vacations {
    margin-bottom: 10px !important; }
    .vacations .hospital-section {
      min-height: 100px !important; }
  .personal-sprint .sprints .sprint-issues {
    margin-left: 15px; }
  .line {
    margin: 10px auto !important; }
  .sprint-employees-item {
    margin: 10px 15px 0 15px !important; }
  .admin-employees-list-item, .admin-vacations-list-item {
    width: 100% !important; }
  .sprint-day-info__start-time::after {
    display: none; }
  .sprint-day-worklog-item__title {
    white-space: nowrap !important; }
  .sprints-list .sprints-list-item {
    margin: 3px;
    font-size: 15px;
    width: 50vw;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px #9B9B9B solid; }
  .navbar-brand {
    padding-right: 15px !important; }
  .toggler-icon {
    margin: 0 15px; }
  .vacations .border-right {
    border: none; }
  .vacations .rdt {
    margin: 20px 70px; }
  .vacations .vacation-section-content {
    padding: 20px; }
  .personal {
    padding-top: 20px; }
    .personal .personal-info {
      border: none; }
    .personal .salary {
      padding-left: 14px !important; }
    .personal .employee-form {
      padding-right: 20px;
      padding-left: 20px; }
    .personal .personal-info-date {
      margin: 10px 0; }
  .workstate {
    padding-top: 5px; }
    .workstate > div > :first-child > :last-child {
      margin-bottom: 0; }
    .workstate .department-title, .workstate .department-header {
      display: none !important; }
    .workstate .user-right-column .user-work-status {
      left: -44px !important; }
  .productivity {
    margin: 0 !important;
    width: 100%; }
    .productivity .about-user {
      border-top: #F0F0F0 solid 1px;
      padding-top: 15px; }
    .productivity .section-header .badge {
      display: none; }
  .sprints .sprint-day-info__start-time, .sprints .sprint-day-info__end-time {
    font-family: HelveticaNeueRegular; }
  .sprints .sprint-day-info__end-time {
    margin-left: 52px; }
  .sprints .sprint-day-info__duration {
    margin: 8px 0;
    padding-left: 12px;
    font-size: 22px;
    font-family: HelveticaNeueRegular; }
  .sprints .sprint-day-info__mobile_format_dow_date {
    display: block !important;
    font-size: 18px;
    color: #4A4A4A;
    font-family: HelveticaNeueRegular; }
    .sprints .sprint-day-info__mobile_format_dow_date span {
      display: inline-block; }
    .sprints .sprint-day-info__mobile_format_dow_date strong {
      font-family: HelveticaNeueMedium; }
    .sprints .sprint-day-info__mobile_format_dow_date span::first-letter {
      margin-left: 2px;
      text-transform: uppercase; }
  .sprints .sprint-day-info__dow, .sprints .sprint-day-info__date {
    display: none; }
  .sprints .sprint-day-worklog {
    margin-left: 4px; }
  .sprints .sprint-day-worklog-item__link {
    min-width: 55px !important; }
  .productivity .issue-pause .primary {
    display: none !important; }
  .productivity .sprint-day-info__start-time, .productivity .sprint-day-info__end-time {
    font-family: HelveticaNeueRegular; }
  .productivity .sprint-day-info__end-time {
    margin-left: 56px !important; }
  .productivity .sprint-day-info__duration {
    margin: 8px 0;
    padding-left: 12px;
    font-size: 22px;
    font-family: HelveticaNeueRegular; }
  .productivity .sprint-day-info__mobile_format_dow_date {
    display: block !important;
    font-size: 18px;
    color: #4A4A4A;
    font-family: HelveticaNeueRegular; }
    .productivity .sprint-day-info__mobile_format_dow_date span {
      display: inline-block; }
    .productivity .sprint-day-info__mobile_format_dow_date strong {
      font-family: HelveticaNeueMedium; }
    .productivity .sprint-day-info__mobile_format_dow_date span::first-letter {
      margin-left: 2px;
      text-transform: uppercase; }
  .productivity .sprint-day-info__dow, .productivity .sprint-day-info__date {
    display: none; }
  .productivity .user {
    padding: 15px 0;
    margin-bottom: 20px; }
  .productivity .issue-buttons {
    justify-content: flex-end;
    margin-bottom: 0; }
    .productivity .issue-buttons .rounded-circle {
      width: 35px !important;
      height: 35px !important; }
    .productivity .issue-buttons .success i {
      right: 6px;
      top: 10px; }
  .productivity .issue {
    min-height: 80px; }
  .productivity .productivity-issues {
    display: flex;
    flex-direction: column-reverse; }
  .productivity .user-avatar, .productivity .user-info, .productivity .sprint-info, .productivity .jira-links {
    display: none !important; }
  .productivity .main-productivity-block {
    flex-direction: column-reverse;
    background-color: white; }
  .productivity .issue-in-work, .productivity issue-pause {
    width: 100vw;
    margin-top: 10px;
    box-sizing: border-box; }
    .productivity .issue-in-work .c1 a, .productivity issue-pause .c1 a {
      margin-right: 6px !important; }
    .productivity .issue-in-work .c3, .productivity issue-pause .c3 {
      justify-content: flex-start; }
      .productivity .issue-in-work .c3 .defaul, .productivity issue-pause .c3 .defaul {
        display: none !important; }
      .productivity .issue-in-work .c3 .fill, .productivity issue-pause .c3 .fill {
        margin-right: 15px; }
      .productivity .issue-in-work .c3 .issue-hours, .productivity issue-pause .c3 .issue-hours {
        margin: 0 !important; }
  .productivity .issue-pause .c3 .issue-buttons, .productivity .issue-in-work .c3 .issue-buttons {
    margin: 0;
    padding-top: 20px; }
  .productivity .components-label {
    display: none; }
  .productivity .user-active .user-work-status .start-time {
    padding-top: 46px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    height: 147px;
    border-left: solid 1px rgba(255, 255, 255, 0.3);
    font-size: 36px;
    font-family: HelveticaNeueRegular;
    color: white;
    opacity: 1;
    position: absolute;
    right: 7%; }
  .productivity .user-active .user-work-status .start-time:before {
    left: 54% !important;
    opacity: 0.4 !important;
    width: 22px;
    height: 22px;
    color: white;
    top: 23%; }
  .productivity .user-work-status {
    position: relative;
    align-items: center;
    width: 96%;
    margin-left: 2%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 200px;
    border-radius: 10px; }
    .productivity .user-work-status .current-time {
      width: 100% !important;
      padding-top: 2.3%;
      display: flex;
      align-items: center;
      font-family: HelveticaNeueBold;
      font-size: 86px;
      padding-left: 10.4%;
      height: 76%; }
    .productivity .user-work-status .start-time {
      top: 11%;
      padding-top: 10px;
      padding-left: 30px;
      display: flex;
      align-items: center;
      height: 73.5px;
      border-left: solid 1px rgba(255, 255, 255, 0.3);
      font-size: 36px;
      font-family: HelveticaNeueRegular;
      color: white;
      opacity: 1;
      position: absolute;
      right: 7%; }
    .productivity .user-work-status .start-time:before {
      left: 54% !important;
      opacity: 0.4 !important;
      width: 22px;
      height: 22px;
      color: white;
      top: -5%; }
    .productivity .user-work-status .end-time {
      height: 73.5px;
      padding-left: 30px;
      bottom: 11%;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      border-left: solid 1px rgba(255, 255, 255, 0.3);
      font-size: 36px;
      font-family: HelveticaNeueRegular;
      color: white;
      opacity: 1;
      position: absolute;
      right: 7%; }
    .productivity .user-work-status .end-time:after {
      left: 54% !important;
      opacity: 0.4 !important;
      width: 22px;
      height: 22px;
      color: white;
      bottom: -5%; }
    .productivity .user-work-status .worked-hrs {
      display: block;
      left: 10.4%;
      position: absolute;
      font-size: 24px;
      color: white;
      bottom: 13px;
      font-family: HelveticaNeueRegular;
      opacity: 0.5; }
    .productivity .user-work-status .current-date {
      top: 22px;
      position: absolute;
      left: 10.4%;
      font-size: 24px;
      color: white;
      opacity: 0.5;
      font-family: HelveticaNeueRegular;
      display: block; }
  .current-sprint-wrap {
    background: none !important; }
  .navbar-elements .team-link, .navbar-elements .current-sprint, .navbar-elements .current-sprint-numbers {
    display: none !important; }
  .navbar-elements .current-user-info {
    padding: 0; }
    .navbar-elements .current-user-info img, .navbar-elements .current-user-info .user-name, .navbar-elements .current-user-info .current-user-status {
      display: none !important; }
    .navbar-elements .current-user-info .toggler-icon {
      display: block; }
  .navbar-elements .current-user-info:after {
    border: none; } }

@media only screen and (max-width: 918px) {
  .sprints .sprint-list {
    border: none; } }

@media only screen and (max-width: 870px) {
  .sprint-worklogs {
    margin: 0 !important; }
  .sprints .sprint-list {
    margin-top: 0px !important; }
  .sprints .sprint-worklogs {
    width: 100%;
    padding: 15px; }
  .personal-sprint .sprint-issues {
    max-width: 870px !important; }
  .navbar.navbar-light .navbar-nav .nav-link div {
    font-size: 13px; }
  .navbar.navbar-light .navbar-nav .nav-link {
    font-size: 13px; }
  .current-sprint-wrap .current-sprint {
    min-width: auto;
    font-size: 13px; }
  .current-user-info {
    padding-right: 10px; } }

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .personal-sprint .sprints .sprint-issues {
    width: 100vw !important; }
  .personal-sprint .sprints .sprint-issues, .personal-sprint .sprints .sprint-worklogs {
    flex-grow: initial; }
  .personal-sprint .sprints .sprint-issues {
    width: 100%; }
  .vacations .rdt {
    margin: 0; }
  .navbar {
    padding: 0.5rem 0; }
  .navbar-brand {
    padding: 17px 5px 10px; }
  .navbar-brand > div > p {
    font-size: 10px; }
  .navbar-brand > div > div {
    font-size: 12px; }
  .navbar-brand > img {
    margin-right: 3px;
    float: left; }
  .current-sprint-wrap .current-sprint-numbers > div:first-child {
    font-size: 11px; }
  .current-sprint-wrap .current-sprint-numbers > div:nth-child(2) {
    font-size: 8px; }
  .current-sprint-wrap .current-sprint-title {
    font-size: 10px;
    line-height: 12px; }
  .current-user-info .user-name {
    font-size: 12px; }
  .current-sprint-wrap .current-sprint-date {
    font-size: 12px;
    line-height: 15px; }
  .current-sprint-wrap .current-sprint {
    padding: 7px 10px; }
  .current-user-info:after {
    right: 5px; }
  .sprint-day-worklog-item__title {
    height: 15px !important; }
  .sprint-day {
    overflow: hidden; }
  .productivity .last-issue-container .issue {
    padding: 0; }
  .productivity-issues {
    margin-bottom: 20px; }
    .productivity-issues > div {
      padding-right: 0;
      padding-left: 7px; }
  .productivity .section.worklogs .section-content, .productivity .section.paused .section-content, .productivity .section.planned .section-content {
    margin-right: 0; }
    .productivity .section.worklogs .section-content .issue, .productivity .section.paused .section-content .issue, .productivity .section.planned .section-content .issue {
      margin: 0; }
      .productivity .section.worklogs .section-content .issue .c3 .issue-buttons, .productivity .section.paused .section-content .issue .c3 .issue-buttons, .productivity .section.planned .section-content .issue .c3 .issue-buttons {
        margin-bottom: auto; }
  .productivity .section.features {
    display: none; }
  .productivity .section.worklogs .sprint-day-info {
    padding-right: 5px;
    padding-left: 0; }
    .productivity .section.worklogs .sprint-day-info__duration {
      margin: 0;
      line-height: 25px; }
  .productivity .section.worklogs .sprint-day-worklog-item__link {
    min-width: 45px; }
  .productivity .sprint-day-worklog {
    margin-left: 0; }
  .productivity .sprint-day-info__start-time {
    margin-top: 5px; }
  .sprints .sprint-issues .section.waiting .issue, .sprints .sprint-issues .section.done .issue, .sprints .sprint-issues .section.fixing .issue {
    padding: 15px; }
    .sprints .sprint-issues .section.waiting .issue .c2, .sprints .sprint-issues .section.done .issue .c2, .sprints .sprint-issues .section.fixing .issue .c2 {
      min-width: 40px; }
    .sprints .sprint-issues .section.waiting .issue .c3, .sprints .sprint-issues .section.done .issue .c3, .sprints .sprint-issues .section.fixing .issue .c3 {
      min-width: 60px; }
  .sprints .sprint-worklogs .sprint-day {
    padding-left: 5px; }
    .sprints .sprint-worklogs .sprint-day-info {
      min-width: 105px;
      padding: 0 5px 0 5px; }
      .sprints .sprint-worklogs .sprint-day-info__duration {
        margin: 0;
        line-height: 25px; }
  .workstate .user-card {
    margin-right: -15px; }
    .workstate .user-card .user-sprint-hours {
      display: none !important; }
    .workstate .user-card.user-right-column {
      -ms-flex-direction: row;
      flex-direction: row; }
    .workstate .user-card .user-info-wrapper__right {
      -ms-flex-direction: row;
      flex-direction: row; }
    .workstate .user-card .user-info-header__right {
      -ms-flex-align: start;
      align-items: flex-start; }
    .workstate .user-card .issue-title {
      max-height: 34px;
      overflow: hidden;
      line-height: 16px; }
  .personal-sprint .sprints-admin-header {
    display: none; }
  .personal-sprint .sprint-list {
    min-width: 1px; }
    .personal-sprint .sprint-list .sprint {
      max-width: 450px; }
      .personal-sprint .sprint-list .sprint-tasks-info {
        padding: 0 15px 0 0;
        width: calc(50% - 15px); }
      .personal-sprint .sprint-list .sprint-salary-info {
        padding: 0 0 0 15px;
        width: calc(50% - 15px); }
  .personal-sprint .sprint-worklogs {
    padding: 0 0 0 10px; } }

/* Bootstrap Extra Small */
@media only screen and (max-width: 540px) {
  .login-page {
    height: auto !important; }
  .login-page-wrap {
    display: flex;
    justify-content: center;
    background-color: white; }
  .login-page {
    align-items: center;
    width: 320px;
    flex-wrap: wrap;
    flex-direction: column !important;
    position: static; }
    .login-page input {
      width: 125px !important; }
    .login-page .logo {
      margin-right: 30px; }
    .login-page > div {
      margin: 0 !important;
      padding: 0 !important; }
    .login-page :first-child {
      flex-direction: row !important; }
  .user-card .user-name {
    font-size: 20px;
    line-height: 22px; }
  .user-sprint-hours div {
    display: flex;
    flex-wrap: nowrap; }
    .user-sprint-hours div i {
      margin-top: 3px; }
  .last-issue-container .issue .c2 {
    min-width: 50px; } }

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .user-info-container .user {
    margin-top: 0;
    padding-top: 0; }
  .sprint-employees .sprint-employees-item {
    margin: 15px !important; }
    .sprint-employees .sprint-employees-item .sprint-employees-item-user__name {
      font-size: 20px;
      white-space: normal; }
    .sprint-employees .sprint-employees-item .sprint-info-line__value.black {
      font-size: 24px; }
  .admin-vacations-list-item {
    margin: 15px !important; }
    .admin-vacations-list-item .section-content {
      width: 100% !important; }
      .admin-vacations-list-item .section-content .vacation-info .light {
        line-height: 20px; }
    .admin-vacations-list-item img {
      width: 40px;
      height: 40px; }
    .admin-vacations-list-item .control-buttons {
      margin-left: 40px !important; }
      .admin-vacations-list-item .control-buttons button {
        margin: 0 0 0 10px !important;
        font-size: 12px; }
  .admin-employees-list-item {
    margin: 15px;
    padding: 15px;
    flex-wrap: wrap; }
    .admin-employees-list-item img {
      width: 40px;
      height: 40px; }
    .admin-employees-list-item .admin-employees-list-item-user {
      font-size: 16px;
      width: 75%; }
    .admin-employees-list-item .admin-employees-list-item-user__name {
      margin-top: 0; }
  .personal-info .section-content, .personal-info document {
    margin: 0 !important; }
  .personal-info .section-header {
    margin: 0 0 5px 0; }
  .new-document .section-content {
    margin: 0 !important; }
  .new-document .section-header {
    margin: 0 0 5px 0; }
  .document .section-content {
    margin: 0 !important; }
  .document .section-header {
    margin: 0 0 5px 0; }
  .personal-sprint .sprints .sprint-date {
    font-size: 26px; }
  .personal-sprint .sprints .sprint-issues .section-header .badge {
    font-size: 10px !important;
    height: 18px !important;
    padding-right: 9px !important;
    padding-left: 9px !important; }
  .personal-sprint .sprints .sprint-issues .section-content a {
    font-size: 14px !important; }
  .personal-sprint .sprints .sprint-issues .section-content .issue-hours {
    font-size: 14px !important; }
  .personal-sprint .sprints .sprint-issues .section-content .badge {
    margin: 0 !important; }
  .workstate .user-sprint-hours {
    margin-left: 0 !important; }
  .workstate .align-items-start {
    flex-wrap: wrap;
    justify-content: flex-start; }
  .navbar .toggler-icon {
    width: 18px !important;
    height: 18px;
    margin: 0 10px; }
  .navbar .nav-link > div {
    padding: 0 10px !important; }
    .navbar .nav-link > div .badge {
      margin-left: 3px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
      height: 16px !important;
      font-size: 10px !important; }
  .navbar .navbar-brand {
    padding-left: 0; }
    .navbar .navbar-brand p, .navbar .navbar-brand div {
      line-height: 13px; }
  .col-1 {
    padding-left: 0;
    padding-right: 0; }
  .vacations {
    margin-top: 20px; }
    .vacations .section-header {
      margin-left: 15px; }
      .vacations .section-header .badge {
        font-size: 10px;
        height: 18px;
        padding-top: 3px; }
    .vacations .section-content {
      margin: 10px 0px; }
    .vacations .control-dates span {
      padding: 0 !important; }
    .vacations .rdt {
      padding: 0; }
    .vacations .vacation-section-content p {
      font-size: 16px; }
    .vacations .vacation-section-content small {
      font-size: 12px !important; }
    .vacations .section-content p {
      font-size: 16px; }
  .personal .dropdown-menu-component button {
    padding: 4px; }
  .personal .btn {
    padding: 6px 6px;
    font-size: 12px !important; }
  .personal .personal-info-position {
    font-size: 18px; }
  .personal .personal-info .salary-value {
    font-size: 22px; }
  .productivity .issue-in-work, .productivity .issue-pause {
    justify-content: space-between; }
  .productivity .paused .c1, .productivity .planned .c1 {
    margin-left: 0 !important; }
    .productivity .paused .c1 > div a, .productivity .planned .c1 > div a {
      margin-right: 6px; }
    .productivity .paused .c1 > div img, .productivity .planned .c1 > div img {
      min-width: 16px; }
  .productivity .paused .c3 .issue-hours, .productivity .planned .c3 .issue-hours {
    text-align: right; }
  .productivity .paused .c3 div, .productivity .planned .c3 div {
    min-width: 0 !important;
    margin-right: 0 !important; }
  .productivity .issue-in-work .c3 .fill, .productivity .issue-pause .c3 .fill {
    margin-right: 3px; }
  .productivity .issue-in-work .c3 .issue-hours, .productivity .issue-pause .c3 .issue-hours {
    margin-right: 0 !important; }
  .productivity .user-active .user-work-status .start-time {
    padding-top: 28px;
    padding-left: 6.5vw !important;
    height: 106px;
    right: 7%; }
  .productivity .user-active .user-work-status .start-time:before {
    opacity: 1;
    width: 16px;
    left: auto !important;
    right: 18.7% !important;
    height: 14px;
    color: white;
    top: 30%; }
  .productivity .user-work-status {
    height: 145px; }
    .productivity .user-work-status .current-time {
      font-size: 74px; }
    .productivity .user-work-status .current-date {
      font-size: 12px; }
    .productivity .user-work-status .worked-hrs {
      font-size: 12px; }
    .productivity .user-work-status .current-time {
      font-size: 50px; }
    .productivity .user-work-status .start-time {
      padding-left: 6.5vw !important;
      font-size: 18px !important;
      height: 53px;
      right: 7%;
      top: 14.5px; }
    .productivity .user-work-status .end-time {
      padding-left: 6.5vw !important;
      font-size: 18px;
      height: 53px;
      bottom: 14px; }
    .productivity .user-work-status .end-time:after, .productivity .user-work-status .start-time:before {
      width: 19px;
      height: 14.4px;
      right: 7%; }
  .personal-sprint .sprint-worklogs .section-header, .personal-sprint .sprint-issues .section-header, .personal-sprint .section .section-header {
    margin-top: 20px; }
  .vacation-info div {
    line-height: 26px !important; } }

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  .vacations .section-content form small.help {
    font-size: 10px; }
  .vacations .section-content .sick-leave-attachment-curtain {
    padding: 5px; } }

/*==========  Mobile First Method  ==========*/
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
