.workstate {
  padding-top: 20px; }

.department-header .badge {
  font-size: 12px;
  font-family: HelveticaNeueRegular;
  width: 25px;
  height: 19px;
  line-height: 15px;
  border-radius: 10px;
  padding-top: 3px;
  margin: 0 3px; }

.department-header .badge-success {
  background-color: #01BC65; }

.department-title {
  font-size: 24px;
  color: #9b9b9b;
  font-family: "HelveticaNeueRegular";
  letter-spacing: 1px;
  margin: 0 5px; }

.nav-link .badge {
  margin-left: 3px; }

.img-wrap {
  width: 100%;
  height: 100%; }

.user-card {
  -ms-flex-direction: row;
  flex-direction: row;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 7px;
  background-color: #fff;
  position: relative;
  cursor: default;
  opacity: .6;
  font-family: "Helvetica Neue";
  margin: 15px 8px; }
  .user-card.user-active {
    opacity: 1; }
  .user-card .user-name {
    font-size: 22px;
    font-family: "HelveticaNeueMedium"; }
  .user-card .user-position {
    font-size: 14px;
    color: #a6a6a6;
    font-family: "HelveticaNeueRegular"; }
  .user-card .user-info {
    flex-grow: 1; }
    .user-card .user-info-wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-direction: row;
      flex-direction: row; }
      .user-card .user-info-wrapper__right {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
    .user-card .user-info-header {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: start;
      align-items: flex-start; }
      .user-card .user-info-header__right {
        -ms-flex-align: end;
        align-items: flex-end; }
  .user-card .avatar {
    width: 90px;
    height: 90px; }

.user-sprint-hours i {
  font-size: 10px;
  margin-right: 5px;
  color: #a6a6a6; }

.user-sprint-hours span {
  font-size: 13px;
  color: #a6a6a6;
  font-family: "HelveticaNeueRegular"; }

.user-sprint-hours > div:nth-child(2) > span:nth-child(3) {
  color: #C28D93; }

.issue-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: start;
  align-items: flex-start; }
  .issue-item img {
    height: 16px;
    width: 16px; }
  .issue-item .issue-link {
    padding: 0 0.25rem;
    cursor: pointer; }
    .issue-item .issue-link a {
      font-family: "HelveticaNeueBold";
      font-size: 16px;
      color: #4990e2;
      white-space: nowrap; }
  .issue-item .issue-image-wrap {
    padding: 0; }
  .issue-item .issue-title {
    line-height: 17px;
    font-size: 14px;
    font-family: "HelveticaNeueRegular"; }

.user-work-status {
  background-color: #9b9b9b;
  position: absolute;
  width: 78px;
  height: 78px;
  top: 18px;
  left: -44px;
  border: 3px #fff solid;
  cursor: pointer; }
  .user-work-status .start-time,
  .user-work-status .current-time,
  .user-work-status .end-time {
    color: #fff; }
  .user-work-status .start-time,
  .user-work-status .end-time {
    font-size: 11px;
    opacity: 0.7; }
  .user-work-status .start-time:before {
    content: '';
    width: 8px;
    height: 8px;
    top: 4px;
    left: 33px;
    position: absolute;
    display: inline-block;
    background: url(../images/arrow-down.svg) no-repeat;
    -webkit-background-size: contain;
    background-size: contain; }
  .user-work-status .end-time:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 8px;
    height: 8px;
    bottom: 3px;
    left: 33px;
    background: url(../images/arrow-down.svg) no-repeat;
    -webkit-background-size: contain;
    background-size: contain; }
  .user-work-status .current-time {
    font-size: 24px;
    line-height: 22px;
    font-weight: bold; }

.user-active .user-work-status {
  background-color: #01BC65; }
  .user-active .user-work-status .end-time {
    visibility: hidden; }

.user-vacation .user-work-status .start-time,
.user-vacation .user-work-status .end-time,
.user-notworked .user-work-status .start-time,
.user-notworked .user-work-status .end-time,
.user-hospital .user-work-status .start-time,
.user-hospital .user-work-status .end-time {
  visibility: hidden; }

.user-vacation .user-work-status {
  background-color: #4990e2; }

.user-hospital .user-work-status {
  background-color: #d0011b; }

.user-right-column {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }
  .user-right-column .user-work-status {
    left: auto;
    right: -44px; }

.logout-area {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 0;
  right: 0; }
