.admin-vacations-list {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  white-space: nowrap; }
  .admin-vacations-list-item {
    width: calc(50% - 40px);
    margin: 20px;
    display: inline-block; }
    .admin-vacations-list-item .section {
      margin-top: 25px; }
      .admin-vacations-list-item .section-header {
        cursor: default;
        font-size: 24px;
        color: #9b9b9b;
        font-family: "HelveticaNeueRegular";
        margin-bottom: 5px; }
        .admin-vacations-list-item .section-header span {
          font-size: 24px;
          color: #000;
          margin: 0 5px; }
        .admin-vacations-list-item .section-header .badge {
          font-size: 14px;
          font-family: "HelveticaNeueBold";
          line-height: 15px;
          font-weight: 100;
          height: 20px;
          padding-left: 6px;
          vertical-align: middle;
          margin-right: 5px;
          width: 25px;
          text-align: center;
          border-radius: 16px; }
      .admin-vacations-list-item .section-content {
        padding: 20px;
        background-color: #fff;
        min-height: 50px;
        border: solid 1px #E2E2E2;
        margin-bottom: 20px; }
        .admin-vacations-list-item .section-content .control-buttons {
          margin-left: 80px; }
        .admin-vacations-list-item .section-content__inactive {
          opacity: 0.4; }
        .admin-vacations-list-item .section-content p {
          font-size: 18px;
          font-family: "HelveticaNeueRegular";
          cursor: default; }
        .admin-vacations-list-item .section-content form {
          padding: 0 20px; }
          .admin-vacations-list-item .section-content form label, .admin-vacations-list-item .section-content form small {
            font-size: 14px;
            font-family: "HelveticaNeueRegular";
            color: #9B9B9B; }
          .admin-vacations-list-item .section-content form small {
            margin: auto 0;
            cursor: default; }
          .admin-vacations-list-item .section-content form b {
            font-family: "HelveticaNeueBold"; }
          .admin-vacations-list-item .section-content form textarea::-webkit-input-placeholder {
            color: #9B9B9B;
            font-size: 14px;
            font-family: "HelveticaNeueRegular"; }
          .admin-vacations-list-item .section-content form textarea:-moz-placeholder {
            color: #9B9B9B;
            font-size: 14px;
            font-family: "HelveticaNeueRegular";
            opacity: 1; }
          .admin-vacations-list-item .section-content form textarea::-moz-placeholder {
            color: #9B9B9B;
            font-size: 14px;
            font-family: "HelveticaNeueRegular";
            opacity: 1; }
          .admin-vacations-list-item .section-content form textarea:-ms-input-placeholder {
            color: #9B9B9B;
            font-size: 14px;
            font-family: "HelveticaNeueRegular"; }
        .admin-vacations-list-item .section-content .control-buttons button {
          margin: 0 20px; }
        .admin-vacations-list-item .section-content .vacation-info {
          white-space: normal;
          padding: 0 10px; }
          .admin-vacations-list-item .section-content .vacation-info div {
            margin: 10px 0;
            cursor: default;
            font-size: 16px;
            font-family: "HelveticaNeueRegular"; }
            .admin-vacations-list-item .section-content .vacation-info div.sick-leave-info-reason {
              margin: 0;
              text-decoration: underline;
              cursor: pointer; }
          .admin-vacations-list-item .section-content .vacation-info b {
            font-family: "HelveticaNeueBold"; }
        .admin-vacations-list-item .section-content .vacation-employee {
          margin: 0 !important; }
        .admin-vacations-list-item .section-content .vacation-dates {
          margin: 0 0 10px 0 !important;
          color: #4A4A4A;
          font-size: 16px;
          font-family: "HelveticaNeueRegular"; }
          .admin-vacations-list-item .section-content .vacation-dates b {
            font-family: "HelveticaNeueBold"; }
        .admin-vacations-list-item .section-content .sick-leave-info {
          padding: 0 0 0 10px;
          width: 100%; }
          .admin-vacations-list-item .section-content .sick-leave-info-field {
            cursor: default;
            margin: 10px 0;
            font-size: 16px;
            font-family: "HelveticaNeueRegular"; }
          .admin-vacations-list-item .section-content .sick-leave-info div:first-child {
            margin: 0; }
          .admin-vacations-list-item .section-content .sick-leave-info b {
            font-family: "HelveticaNeueBold"; }
        .admin-vacations-list-item .section-content .sick-leave-attachment-curtain {
          height: 42px;
          background: #D8D8D8;
          opacity: 0.3;
          font-size: 12px;
          width: 100%;
          margin: 15px 0;
          padding: 10px 15px; }
          .admin-vacations-list-item .section-content .sick-leave-attachment-curtain input[type=file] {
            display: none; }
          .admin-vacations-list-item .section-content .sick-leave-attachment-curtain.is-dragover {
            background-color: #fff;
            outline: 2px dashed #92b0b3 !important; }
        .admin-vacations-list-item .section-content .attachment-preview {
          width: 100%;
          max-height: 400px; }
          .admin-vacations-list-item .section-content .attachment-preview-screencover {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: auto;
            height: auto;
            box-sizing: border-box;
            z-index: 2;
            opacity: 0;
            background-color: #000; }
          .admin-vacations-list-item .section-content .attachment-preview-wrapper {
            width: 100%;
            margin-bottom: 20px;
            position: relative; }
            .admin-vacations-list-item .section-content .attachment-preview-wrapper i {
              font-family: 'FontAwesome';
              font-style: normal; }
            .admin-vacations-list-item .section-content .attachment-preview-wrapper:hover .attachment-preview-screencover {
              opacity: 0.6; }
            .admin-vacations-list-item .section-content .attachment-preview-wrapper:hover .attachment-preview__remove {
              opacity: 1; }
          .admin-vacations-list-item .section-content .attachment-preview__remove {
            position: absolute;
            top: calc(50% - 10px);
            left: calc(50% - 10px);
            opacity: 0;
            z-index: 10;
            color: white;
            font-size: 35px;
            cursor: pointer; }
        .admin-vacations-list-item .section-content .light {
          line-height: 13px;
          color: #9b9b9b;
          font-size: 15px;
          font-family: "HelveticaNeueRegular";
          margin: 0;
          cursor: default; }

.admin-employees-list-item {
  width: calc(50% - 40px);
  padding: 20px;
  background-color: #fff;
  border: solid 1px #E2E2E2;
  margin: 20px; }
  .admin-employees-list-item:active, .admin-employees-list-item:focus, .admin-employees-list-item:hover {
    text-decoration: none; }
  .admin-employees-list-item-user {
    font-size: 22px;
    color: #000;
    line-height: 24px;
    margin-left: 10px; }
    .admin-employees-list-item-user__name {
      font-family: "HelveticaNeueMedium";
      margin-top: 10px; }
    .admin-employees-list-item-user__surname {
      font-family: "HelveticaNeueMedium"; }
    .admin-employees-list-item-user__position {
      font-size: 14px;
      font-family: "HelveticaNeueRegular";
      color: #a6a6a6; }
  .admin-employees-list-item-sprints {
    margin-left: 10px; }
    .admin-employees-list-item-sprints .badge {
      padding: 5px 10px;
      border-radius: 8px; }
    .admin-employees-list-item-sprints-year {
      margin: 0 5px; }
      .admin-employees-list-item-sprints-year > div:first-child {
        font-size: 14px;
        font-family: "HelveticaNeueRegular";
        color: #a6a6a6; }
