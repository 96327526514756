body {
  background-color: #e7e7e7; }

.blueline {
  background-color: #0261A1;
  width: 100vw;
  height: 3px;
  position: absolute;
  z-index: 2;
  top: 0; }

.menu {
  height: 60px;
  display: flex !important;
  justify-content: space-between; }
  .menu > div {
    align-items: center;
    display: flex; }

.toggler-icon {
  background-image: url("../images/hamburger-grey.svg");
  background-repeat: no-repeat;
  width: 24px !important;
  height: 24px;
  -webkit-background-size: contain;
  background-size: contain; }

.navbar {
  height: 60px;
  float: none;
  max-width: none;
  border-top: 1px solid #F5F5F5;
  border-bottom: 1px solid #F5F5F5;
  background-color: #F8F8F8; }
  .navbar .navbar-elements {
    width: 100%;
    align-items: center;
    display: flex !important;
    flex-wrap: nowrap; }
  .navbar-brand {
    display: flex;
    color: #898989;
    margin-right: 0;
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 1px;
    text-transform: capitalize;
    font-family: 'Raleway', sans-serif;
    -webkit-transition: height .4s ease, line-height .4s ease, margin .4s ease, padding .4s ease;
    transition: height .4s ease, line-height .4s ease, margin .4s ease, padding .4s ease;
    line-height: 14px;
    height: 60px;
    padding: 17px 20px 10px; }
    .navbar-brand > img {
      margin-right: 5px;
      float: left;
      width: 25px; }
    .navbar-brand > div {
      float: right; }
      .navbar-brand > div > p {
        font-size: 12px;
        margin-bottom: 0; }
      .navbar-brand > div > div {
        font-size: 15px;
        line-height: 20px;
        font-weight: bold; }
  .navbar-nav {
    opacity: 1;
    -webkit-transition: height .4s ease, margin .4s ease, opacity .2s .2s ease;
    -o-transition: height .4s ease, margin .4s ease, opacity .2s .2s ease;
    transition: height .4s ease, margin .4s ease, opacity .2s .2s ease;
    height: 60px;
    list-style: none;
    margin: 0; }
  .navbar-toggler {
    cursor: pointer;
    padding: 0;
    left: 10px;
    top: 15px;
    border: none; }
    .navbar-toggler:focus {
      outline: none; }
    .navbar-toggler-icon {
      width: 1em;
      height: 1em; }
  .navbar.navbar-light {
    flex-wrap: nowrap;
    flex-direction: row; }
    .navbar.navbar-light .navbar-nav .nav-link {
      display: flex;
      align-items: center;
      color: #898989;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: capitalize;
      font-family: "HelveticaNeueRegular";
      -webkit-transition: height .4s ease, line-height .4s ease, margin .4s ease, padding .4s ease;
      -o-transition: height .4s ease, line-height .4s ease, margin .4s ease, padding .4s ease;
      transition: height .4s ease, line-height .4s ease, margin .4s ease, padding .4s ease;
      line-height: 14px;
      border-top: 5px solid transparent;
      height: 60px;
      line-height: 14px;
      padding: 0; }
      .navbar.navbar-light .navbar-nav .nav-link div {
        white-space: nowrap; }
      .navbar.navbar-light .navbar-nav .nav-link > div {
        padding: 0 40px 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative; }
        .navbar.navbar-light .navbar-nav .nav-link > div > div {
          position: relative;
          display: block;
          float: right;
          height: 18px;
          padding: 0px;
          font-size: 12px;
          text-align: center;
          bottom: 4px;
          padding-top: 4px;
          margin-top: 6px;
          margin-left: 7px;
          padding-left: 12px;
          padding-right: 12px; }
    .navbar.navbar-light .navbar-nav li:hover > .nav-link,
    .navbar.navbar-light .navbar-nav .active > .nav-link {
      background-color: #E7E7E7;
      color: black;
      font-family: "HelveticaNeueMedium"; }

.nav-item {
  position: relative;
  height: 60px;
  float: left;
  background: url(../images/menu-divider.png) no-repeat right center;
  margin-left: 1px; }
  .nav-item:first-child {
    padding-left: 0; }
    .nav-item:first-child a > div {
      font-size: 15px;
      color: black;
      font-family: "HelveticaNeueMedium"; }
  .nav-item .badge {
    font-family: HelveticaNeueRegular; }

.alert {
  margin-bottom: 0 !important; }

.current-sprint-wrap {
  cursor: default;
  height: 60px;
  padding: 12px 0; }
  .current-sprint-wrap .current-sprint {
    min-width: 200px;
    padding: 2px 160px 2px 18px; }
    .current-sprint-wrap .current-sprint > a {
      text-decoration: none; }
    .current-sprint-wrap .current-sprint-title {
      font-size: 11px;
      font-family: "HelveticaNeueRegular";
      line-height: 11px;
      color: #898989; }
    .current-sprint-wrap .current-sprint-date {
      font-size: 15px;
      font-family: "HelveticaNeueMedium";
      line-height: 20px;
      color: #292b2c; }
  .current-sprint-wrap .current-sprint-numbers {
    height: 40px; }
    .current-sprint-wrap .current-sprint-numbers > div:first-child {
      font-size: 13px;
      font-family: "HelveticaNeueMedium";
      color: #9b9b9b;
      text-align: right;
      padding-left: 10px; }
    .current-sprint-wrap .current-sprint-numbers > div:nth-child(2) {
      font-size: 10px;
      font-family: "HelveticaNeueMedium";
      color: #858f9b;
      text-align: right; }
      .current-sprint-wrap .current-sprint-numbers > div:nth-child(2) > span:nth-child(2) {
        color: #C28D93; }

.current-user-info {
  align-items: center;
  cursor: pointer;
  padding: 0px 20px 0 10px; }
  .current-user-info .toggler-icon {
    display: none;
    background-image: url("../images/hamburger-blue.svg");
    width: 24px !important;
    height: 24px; }
  .current-user-info:after {
    color: #c9c9c9;
    position: absolute;
    right: 10px;
    top: 25px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: 0.6em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent; }
  .current-user-info img {
    width: 35px;
    height: 35px; }
  .current-user-info > div {
    padding-left: 5px;
    white-space: nowrap; }
  .current-user-info .user-name {
    font-size: 15px;
    font-family: "HelveticaNeueMedium";
    font-weight: 600; }
  .current-user-info .dropdown-menu {
    width: 240px;
    top: 50px;
    right: 15px;
    left: auto;
    -webkit-box-shadow: lightgrey 0px 5px 9px 0px;
    -moz-box-shadow: lightgrey 0px 5px 9px 0px;
    box-shadow: lightgrey 0px 5px 9px 0px;
    font-family: HelveticaNeueRegular;
    font-size: 14px;
    padding-top: 0; }
    .current-user-info .dropdown-menu h6 {
      font-size: 12px;
      opacity: 0.5; }

.current-user-status > div:nth-child(1) {
  width: 10px;
  height: 10px;
  background-color: #9b9b9b; }

.current-user-status > div:nth-child(1).online {
  background-color: #01BC65; }

.current-user-status > div:nth-child(2) {
  color: #9b9b9b;
  font-size: 12px;
  font-family: "HelveticaNeueRegular";
  margin: 0 3px; }

.current-user-status > div:nth-child(2).online {
  color: #01BC65; }

.current-user-status > div:nth-child(3) {
  font-size: 11px;
  font-family: "HelveticaNeueRegular";
  color: #9b9b9b; }

.show .navbar-brand {
  display: inline-block;
  padding-top: 7px; }

.show .nav-item {
  background: none;
  border-bottom: 1px solid #ededed; }

.show .navbar-nav {
  background-image: none;
  height: auto;
  position: absolute;
  top: 55px;
  width: 100%;
  background-color: #f7f7f7;
  z-index: 1;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }

.show .current-sprint-wrap,
.show .current-user-info {
  display: none !important; }

.divider-right {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkAQMAAAByusJPAAAAA1BMVEXMzMzKUkQnAAAAAXRSTlOArV5bRgAAAAtJREFUeF5joBIAAABIAAG5eQDrAAAAAElFTkSuQmCC) no-repeat right center; }

.divider-left {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkAQMAAAByusJPAAAAA1BMVEXMzMzKUkQnAAAAAXRSTlOArV5bRgAAAAtJREFUeF5joBIAAABIAAG5eQDrAAAAAElFTkSuQmCC) no-repeat left center; }

.dropdown-menu.show {
  top: 52px;
  width: 240px;
  margin: 0 15px;
  -webkit-box-shadow: lightgrey 0px 5px 9px 0px;
  -moz-box-shadow: lightgrey 0px 5px 9px 0px;
  box-shadow: lightgrey 0px 5px 9px 0px;
  font-family: HelveticaNeueRegular;
  display: block;
  font-size: 14px; }
  .dropdown-menu.show h6 {
    font-size: 12px;
    opacity: 0.5; }

.align-items-end {
  display: flex;
  flex-wrap: nowrap; }
