@font-face {
  font-family: "HelveticaNeueBold";
  src: url(./fonts/HelveticaNeueCyr-Bold.otf); }

@font-face {
  font-family: "HelveticaNeueMedium";
  src: url(./fonts/HelveticaNeueCyr-Medium.otf); }

@font-face {
  font-family: "HelveticaNeueRegular";
  font-weight: 100;
  src: url(./fonts/HelveticaNeueCyr-Light.otf); }

@font-face {
  font-family: "HelveticaNeueItalic";
  font-weight: 100;
  src: url(./fonts/HelveticaNeueCyr-Italic.otf); }

@font-face {
  font-family: "HelveticaNeueThinItalic";
  font-weight: 100;
  src: url(./fonts/HelveticaNeueCyr-ThinItalic.otf); }
